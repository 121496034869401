<template>
  <div v-if="isLoading" class="w-100 h-100 d-flex align-items-center justify-content-center">
    <loading-content />
  </div>

  <div v-else class="service-edit container">
    <b-row>
      <b-col cols="12" class="d-flex align-items-center">
        <div role="button" @click="discardAndBack">
          <feather type="arrow-left" class="mr-3" />

          <span class="sr-only">Back</span>
        </div>

        <h2 class="m-0">{{ pageTitle }}</h2>
      </b-col>
    </b-row>

    <b-form class="mt-4" id="serviceForm" @submit.prevent="handleSubmit" @reset.prevent="handleReset">
      <b-tabs content-class="m-3">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Service name" label-for="service-name" class="service-edit__form-group-name"
                :state="isValid('service.name')" :invalid-feedback="handleError('service.name')">
                <b-form-textarea id="service-name" v-model="service.name" :disabled="isEditing"/>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Service short description" label-for="service-description" class="service-edit__form-group-description"
                :state="isValid('service.description')" :invalid-feedback="handleError('service.description')">
                <b-form-textarea id="service-description" rows="5" v-model="service.description"/>
              </b-form-group>
            </b-col>
          </b-row>
      </b-tabs>
    </b-form>
    <div class="service-edit__fab-menu d-flex position-fixed">
      <b-button form="serviceForm" type="reset" class="px-3 mr-4">
        Cancel
      </b-button>

      <b-button variant="primary" form="serviceForm" type="submit" class="px-5 position-relative"
        :disabled="!isValidForm || isLoadingUpdate">
        <b-spinner v-if="isLoadingUpdate" class="position-absolute service-edit__btn-save-spinner" small />

        {{ isEditing ? 'Save' : 'Create' }}
      </b-button>
    </div>
  </div>
</template>

<script>
import errorHandler from '@/mixins/errorHandler';
import { required } from 'vuelidate/lib/validators';
import axios from 'axios';
import LoadingContent from '../../../components/LoadingContent.vue';

export default {
  name: 'ServiceEdit',
  components: {
    LoadingContent,
  },
  props: {
    id: {
      type: [ String, Number ],
      default: '',
    },
  },
  mixins: [ errorHandler ],
  data() {
    return {
      errorMessages: {
        required: 'This field is required',
      },
      service: {
        name: '',
        description: '',
      },
      isLoading: false,
      isLoadingUpdate: false,
    };
  },
  computed: {
    serviceId() {
      return Number(this.id);
    },
    isEditing() {
      return this.$route.path.includes('edit');
    },
    pageTitle() {
      return this.isEditing ? `Edit vendor service ${this.service.name}` : 'Add New Vendor Service';
    },
    isValidForm() {
      return !this.$v.service.$invalid;
    },
  },
  validations() {
    return {
      service: {
        name: { required },
        description: { required },
      },
    };
  },
  methods: {
    validateIsArrayAndLength(array) {
      return Array.isArray(array) && array.length;
    },
    discardAndBack() {
      this.resetValues();
      this.$router.push({ name: 'CareEcosystemServices' });
    },
    fetchService() {
      axios.get(`/v1/care-ecosystem/services/${this.serviceId}`)
        .then(responseService => {
          this.service = {
            name: responseService.data.name,
            description: responseService.data.description,
          };
        }).catch(err => {
          this.$noty.error(`Failed to fetch care ecosystem service: ${err}`);
        }).finally(() => {
          this.isLoading = false;
        });
    },
    handleCreate() {
      axios.post('/v1/care-ecosystem/services', this.service)
        .then(response => {
          if (response) {
            this.$noty.success('Service added successfully');

            this.resetValues();
            this.$router.push({ name: 'CareEcosystemServices' });
          }
        })
        .catch(err => {
          if (err.response && err.response.status === 409) {
            this.$noty.error('This service name already exists');
          } else {
            this.$noty.error(`Failed to add care ecosystem service: ${err}`);
          }
        })
        .finally(() => {
          this.isLoadingUpdate = false;
        });
    },
    handleEdit() {
      const filteredService = {
        description: this.service.description,
      };
      axios.put(`/v1/care-ecosystem/services/${this.serviceId}`, filteredService)
        .then(response => {
          if (response) {
            this.$noty.success('Service updated successfully');

            this.resetValues();
            this.$router.push({ name: 'CareEcosystemServices' });
          }
        })
        .catch(err => {
          this.$noty.error(`Failed to update care ecosystem service: ${err}`);
        })
        .finally(() => {
          this.isLoadingUpdate = false;
        });
    },
    handleSubmit() {
      if (this.isValidForm) {
        this.isLoadingUpdate = true;

        if (this.isEditing) {
          this.handleEdit();
        } else {
          this.handleCreate();
        }
      }
    },
    resetValues() {
      this.service = {
        name: '',
        description: '',
      };
      this.editServiceModalData = null;
    },
    handleReset() {
      this.resetValues();
      this.discardAndBack();
    },
  },
  beforeMount() {
    if (this.isEditing) {
      this.isLoading = true;

      this.fetchService();
    }
  },
};
</script>

<style lang="scss">
.service-edit {
  &__form-group-name {
    max-width: 480px;
  }

  &__form-group-description {
    max-width: 480px;
  }

  &__fab-menu {
    bottom: 32px;
    right: 32px;
    z-index: 10;
  }

  &__btn-save-spinner {
    left: 16px;
    top: 9px;
  }
}
</style>
